import * as React from "react"
import { ImgDestra, ImgSinistra, ImgCentro, ImgLogo, Img, Citazione, IndiceLuoghi, PaginaTesto, ChiSono, ChiSiamo, A, FbPage } from "../../../components/componentiPagine"

export default function Render() {
	return <PaginaTesto lang="it" name="canada1842">
<h1>Una parentesi nordamericana</h1>
<ImgDestra nome="ambizioni.png" alt="ambizioni"/>
<p>In contemporanea alle collaborazioni con le riviste letterarie inglesi e con l’uscita del l’opera sulla situazione italiana, Antonio inizia a collaborare alle riviste fiorentine di Giovan Pietro Vieusseux.</p>
<p>Tuttavia, a causa del suo carattere perennemente insoddisfatto e forse con la speranza ancora evasa di ottenere un incarico di prestigio in ambito accademico, decide di ritentare la carriera in Nord America.</p>
<ImgCentro nome="canada.PNG" alt="Canada"/>
<p>All’inizio del 1842 si imbarca per il Canada e raggiunge la città di Windsor, dove lo attende una cattedra di lingue e letterature moderne.</p>
<ImgCentro nome="windsor.png" alt="windosr"/>
<p>Rimane in Nuova Scozia per un solo anno e, insoddisfatto del livello del college, si dimette per tornare a Londra, dove lo aspetta la solita vita di sacrifici.</p>
<h2>Link per approfondire</h2>
<p><A href="https://www.treccani.it/enciclopedia/giovan-pietro-vieusseux_%28Dizionario-Biografico%29/">“Giovan Pietro Vieusseux”</A></p>
<p><A href="https://en.wikipedia.org/wiki/Windsor,_Nova_Scotia ">“Windsor”</A></p>

	</PaginaTesto>
}